@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  position: relative;
  isolation: isolate;

  &:hover,
  &:focus-within {
    .image {
      transform: scale(1.1);
    }

    .title {
      text-decoration: underline;
    }
  }

  // Featured tile styles
  &.featured {
    .image {
      aspect-ratio: 1064/554;
    }
    .content {
      padding: pxToRem(20);
      grid-template:
        '🐶 🐱' auto
        '🐭 🐭' auto / auto auto;
    }
    .title {
      @include fontSize(26, 32);
    }
    .description {
      display: none;
    }
  }
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.imageWrapper {
  overflow: hidden;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 340/180;
  transition: transform ease-in-out 300ms;
}

.content {
  display: grid;
  grid-template:
    '🐶 🐱' auto
    '🐭 🐭' auto
    '🐹 🐹' auto / auto auto;
  gap: pxToRem(8);
  padding: pxToRem(24) 0 0;
}

.articleType {
  grid-area: 🐶;
  position: relative;
  z-index: 2;
  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

.publication {
  grid-area: 🐱;
  text-align: right;
}

.articleType,
.publication {
  @include fontSize(12, 16);
  font-weight: 800;
  color: var(--tex-def-tie3);
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: pxToRem(1.2);
}

.title {
  grid-area: 🐭;
  @include fontSize(20, 28);
  font-weight: 800;
  margin: pxToRem(8) 0 0;
}

.description {
  grid-area: 🐹;
  @include fontSize(15, 20);
  margin: 0;
}

@include md {
  .featured {
    .content {
      width: pxToRem(370);
      position: absolute;
      bottom: pxToRem(24);
      left: pxToRem(24);
      border-radius: var(--cor_rad-xs);
      background: var(--bac-tie1);
      padding: pxToRem(16);
    }
  }
}
